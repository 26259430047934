import { createSelector } from 'reselect';
import _ from 'lodash';
import { areaShopSubLocationsSelector } from './markenStandort';
import { standortStateSelector } from '../standort';

const subLocationSelector = createSelector([standortStateSelector], standortState => standortState.subLocation ?? {});

const selectedSublocationUrlSelector = createSelector([subLocationSelector], subLocation => {
    return subLocation.selectedSublocationUrl;
});

export const selectedSublocationSelector = createSelector(
    [areaShopSubLocationsSelector, selectedSublocationUrlSelector],
    (areaShopSubLocations, selectedSublocationUrl) => {
        if (areaShopSubLocations.length === 1) {
            return _.first(areaShopSubLocations);
        }
        return areaShopSubLocations.find(subLocation => subLocation.subLocationUrl === selectedSublocationUrl);
    }
);
export const selectedCarBrandSelector = createSelector([subLocationSelector], subLocation => subLocation.selectedCarBrand);

export const selectedSublocationCarBrandsSelector = createSelector([selectedSublocationSelector], selectedSublocation => {
    return selectedSublocation ? subLocationToCarBrands(selectedSublocation) : [];
});

export function subLocationToCarBrands(subLocation) {
    return [subLocation.hauptFabrikat, ...(subLocation.nebenFabrikat ?? [])];
}
