import { createSelector } from 'reselect';
import { standortStateSelector } from '../../standort';
import { sessionIdSelector } from '../../auth';

const sessionByIdSelector = createSelector([standortStateSelector], standortState => standortState.session);

export const sessionSelector = createSelector(
    [sessionByIdSelector, sessionIdSelector],
    (sessionById, sessionId) => sessionById?.[sessionId] ?? {}
);
