import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BreadCrumbList from './BreadCrumbList';
import SubNavLeft from './SubNavLeft';
import SubNavRight from './SubNavRight';
import Search from '../Search/Search';
import { categoriesSelector } from '../../../modules/selectors/standort/categories';
import { categoryUrlSelector, standortUrlSelector } from '../../../modules/selectors/url';
import useWindowSize from '../../../helpers/windowHelper';
import { markenStandortThemeHeaderSelector } from '../../../modules/selectors/standort/theme';
import { getBaseCrumbs } from './breadCrumbHelper';
import { saveAdditionalCrumbsIfNeeded } from '../../../modules/actions/additionalCrumbs';

// must be in sync with $breakpoint-large
const BREAKPOINT_LARGE = 1200;
// must be in sync with $breakpoint-medium
const BREAKPOINT_MEDIUM = 960;
// must be in sync with $breakpoint-small
const BREAKPOINT_SMALL = 640;

function BreadCrumb({ additionalCrumbs = [], showOptionsSidebarToggle }) {

    const markenStandortThemeHeader = useSelector(markenStandortThemeHeaderSelector);
    const standortUrl = useSelector(standortUrlSelector);
    const categories = useSelector(categoriesSelector);
    const categoryUrl = useSelector(categoryUrlSelector);
    const [showMobileSearchbar, setShowMobileSearchbar] = useState(false);
    const [offsetTop, setOffsetTop] = useState(60);
    const couponBannerHeight = useSelector(state => state.couponHeight)

    const { width } = useWindowSize();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(saveAdditionalCrumbsIfNeeded(additionalCrumbs));
    }, []);

    useEffect(() => {
        if (
            width >= BREAKPOINT_LARGE &&
            // only close mobile searchbar if already open
            showMobileSearchbar
        ) {
            setShowMobileSearchbar(false);
        }
    }, [showMobileSearchbar, width]);

    useEffect(() => {
        const height = markenStandortThemeHeader.height;

        if (!height) {
            console.error('Expected markenStandortThemeHeader.height, got:', height);
            return;
        }

        if (width >= BREAKPOINT_LARGE) {
            setOffsetTop(couponBannerHeight != null ? height.large + couponBannerHeight.data : height.large);
        } else if (width >= BREAKPOINT_MEDIUM) {
            setOffsetTop(couponBannerHeight != null ? height.medium + couponBannerHeight.data : height.medium);
        } else if (width >= BREAKPOINT_SMALL) {
            setOffsetTop(couponBannerHeight != null ? height.small + couponBannerHeight.data : height.small);
        } else {
            setOffsetTop(couponBannerHeight != null ? height.fallback + couponBannerHeight.data : height.fallback);
        }
    }, [couponBannerHeight, markenStandortThemeHeader, width]);
    const crumbs = useMemo(() => {
        const crumbs = getBaseCrumbs({ standortUrl, categories, categoryUrl });
        return [...crumbs, ...additionalCrumbs];
    }, [standortUrl, categories, categoryUrl, additionalCrumbs]);

    return (
        <div className="uk-sticky-fixed uk-width-1-1" style={{ height: 60, position: 'sticky', top: offsetTop }}>
            <section
                className="uk-section uk-section-default uk-section-small uk-padding-small uk-padding-remove-horizontal"
                style={{ borderBottom: '1px solid #e5e5e5' }}
            >
                <div className="uk-container">
                    <div className="uk-flex uk-flex-middle uk-margin-small-top vehicle-selection-dropdown-boundary">
                        {showOptionsSidebarToggle && (
                            <div className="uk-width-auto uk-hidden@m">
                                <SubNavLeft />
                            </div>
                        )}
                        {!showMobileSearchbar && (
                            <div className="uk-width-expand">
                                <BreadCrumbList crumbs={crumbs} />
                            </div>
                        )}
                        {showMobileSearchbar && (
                            <div className="uk-width-expand uk-flex-1">
                                <Search />
                            </div>
                        )}
                        <div className="uk-flex uk-flex-nowrap">
                            <SubNavRight showMobileSearchbar={showMobileSearchbar} setShowMobileSearchbar={setShowMobileSearchbar} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BreadCrumb;
