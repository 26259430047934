import { apiClient } from '../../../../helpers/apiHelperV2';
import { FETCH_CART_STATS_FAILURE, FETCH_CART_STATS_REQUEST, FETCH_CART_STATS_SUCCESS } from '../../../actionTypes';
import { shouldFetch } from '../../../fetchStatus';
import { standortUrlSelector } from '../../../selectors/url';
import { cartStatsResponseSelector } from '../../../selectors/standort/session/cartStats';
import { sessionIdSelector } from '../../../selectors/auth';

export const fetchCartStatsIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot fetch cart stats server-side.');

        const standortUrl = standortUrlSelector(state);
        const cartStatsResponse = cartStatsResponseSelector(state);

        if (shouldFetch(cartStatsResponse.status)) {
            dispatch({
                type: FETCH_CART_STATS_REQUEST,
                payload: { standortUrl, sessionId },
            });
            try {
                const res = await apiClient.get(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/stats`);

                dispatch({
                    type: FETCH_CART_STATS_SUCCESS,
                    payload: { standortUrl, sessionId, cartStats: res.data.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_CART_STATS_FAILURE,
                    payload: { standortUrl, sessionId, error, hideNotification: error?.code === 404 },
                });
            }
        }
    };
};
