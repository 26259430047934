import React from 'react';
import { InternalLink } from '../form-elements/Link';

function Crumb({ crumb, isActive }) {
    if (isActive) {
        return (
            <div>
                <span>{crumb.name ?? crumb.label}</span>
            </div>
        );
    }
    return (
        <div>
            <InternalLink to={crumb.url}>{crumb.label}</InternalLink>
        </div>
    );
}

export default Crumb;
