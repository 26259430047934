import { find } from 'lodash';

export const carTypes = [
    { label: 'PKW', value: 'PKW', legacy: 'PKW' },
    { label: 'Leicht-LKW', value: 'LLKW', legacy: 'LLKW' },
    { label: 'Offroad / SUV', value: 'OR/SUV', legacy: 'SUV' },
];
export const allSeasonOptions = [
    { label: 'Sommerreifen', value: 'sommerreifen', legacy: 'sommer', type: 'Sommer' },
    { label: 'Winterreifen', value: 'winterreifen', legacy: 'winter', type: 'Winter' },
    { label: 'Ganzjahresreifen', value: 'ganzjahresreifen', legacy: 'ganzjahr', type: 'Ganzjahr' },
];

export const validCarTypes = carTypes.map(carType => carType.value);

export const defaultSeason = (() => {
    const date = new Date();
    let month = date.getMonth() + 1;
    if (month <= 3 || month >= 10) {
        return 'winterreifen';
    } else {
        return 'sommerreifen';
    }
})();

export const convertLegacyTireSeason = ({ tireSeason }) => {
    return find(allSeasonOptions, { legacy: tireSeason.toLowerCase() })?.value;
};
export const convertLegacyAutoTyp = ({ autoTyp }) => {
    return find(carTypes, { legacy: autoTyp })?.value;
};
export const findSeasonOption = ({ season }) => {
    return find(allSeasonOptions, { value: season });
};

export const noiseClassTypes = [
    { digit: '1', alphabetic: 'A' },
    { digit: '2', alphabetic: 'B' },
    { digit: '3', alphabetic: 'C' },
];

const findNoiseClassType = ({ noiseClassType }) => {
    return find(noiseClassTypes, ({ digit, alphabetic }) => digit === noiseClassType || alphabetic === noiseClassType);
};

const getNoiseClassTypeLabel = ({ noiseClassType }) => {
    const alphabeticNoiseClass = findNoiseClassType({ noiseClassType })?.alphabetic;
    return alphabeticNoiseClass ? `(${alphabeticNoiseClass})` : '';
};

export const getNumericNoiseClassType = ({ noiseClassType }) => {
    return findNoiseClassType({ noiseClassType })?.digit ?? '';
};

export const tireLabels = [
    {
        imgSrc: 'https://techno-images.s3.eu-central-1.amazonaws.com/icons/Tanken.png',
        alt: 'tanken',
        label: 'Kraftstoffeffizienz',
        format: tire => tire.rollingResistance || '-',
    },
    {
        imgSrc: 'https://techno-images.s3.eu-central-1.amazonaws.com/icons/Regen.png',
        alt: 'regen',
        label: 'Nasshaftung',
        format: tire => tire.wetGrip || '-',
    },
    {
        imgSrc: 'https://techno-images.s3.eu-central-1.amazonaws.com/icons/Lautstaerke.png',
        alt: 'lautstaerke',
        label: 'Abrollgeräusch',
        format: tire => `${tire.noisePerformance || '-'} db ${getNoiseClassTypeLabel(tire)}`,
    },
];

export const gripSymbols = [
    {
        imgSrc: 'https://techno-images.s3.eu-central-1.amazonaws.com/reifenlabel/snowGripSymbol.png',
        alt: 'Schneegriffigkeit',
        label: 'Schneegriffigkeit',
        filter: tire => tire.hasSnowSymbol,
    },
    {
        imgSrc: 'https://techno-images.s3.eu-central-1.amazonaws.com/reifenlabel/iceGripSymbol.png',
        alt: 'Eisgriffigkeit',
        label: 'Eisgriffigkeit',
        filter: tire => tire.hasIceSymbol,
    },
];

export const speedIndexToSpeed = {
    F: '80',
    G: '90',
    J: '100',
    K: '110',
    L: '120',
    M: '130',
    N: '140',
    P: '150',
    Q: '160',
    R: '170',
    S: '180',
    T: '190',
    U: '200',
    H: '210',
    V: '240',
    W: '270',
    Y: '300',
    VR: '> 210',
    ZR: '> 240',
    'R/T': '190',
    'Y/ZR': '300',
    'W/ZR': '270',
};
