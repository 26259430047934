import React from 'react';
import Autosuggest from 'react-autosuggest';
import Button from '../form-elements/Button';
import { useSearch } from './useSearch';

function Search() {
    const {
        suggestions,
        searchValue,
        executeSearch,
        handleSuggestionsFetchRequested,
        handleSuggestionsClearRequested,
        handleSearchValueChange,
        handleSuggestionSelected,
    } = useSearch();

    return (
        <form
            className="uk-search uk-search-default uk-width-1-1"
            onSubmit={evt => {
                evt.preventDefault();
                executeSearch(searchValue);
            }}
        >
            <Button modifier="link" className="uk-search-icon-flip uk-icon uk-search-icon" onClick={() => executeSearch(searchValue)}>
                <i data-uk-icon="search" />
            </Button>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                onSuggestionSelected={handleSuggestionSelected}
                getSuggestionValue={suggestion => suggestion.title}
                renderSuggestion={suggestion => suggestion.title}
                inputProps={{
                    placeholder: 'Suche ...',
                    value: searchValue,
                    onChange: handleSearchValueChange,
                }}
                theme={{
                    input: 'uk-search-input uk-form-small uk-border-pill uk-width-medium@l',
                    suggestionsContainer: 'uk-drop uk-drop-bottom-left uk-card uk-card-small uk-card-default uk-card-body',
                    suggestionsContainerOpen: 'uk-open',
                    suggestionsList: 'uk-nav uk-nav-default uk-margin-remove',
                    suggestionHighlighted: 'uk-link',
                }}
            />
        </form>
    );
}

export default Search;
