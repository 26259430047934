import { createSelector } from 'reselect';
import { pendingResponse, SUCCESS } from '../../../fetchStatus';
import { cartSelector } from './cart';
import { sessionSelector } from './index';

export const cartStatsResponseSelector = createSelector([sessionSelector, cartSelector], (session, cart) => {
    const fullCartStats = cart?.stats;

    // If the full cart has already been fetched successfully,
    // we fake a successful cartStats response containing the stats field from the full cart.
    return fullCartStats ? { status: SUCCESS, data: fullCartStats } : session.cartStats ?? pendingResponse;
});

export const cartStatsSelector = createSelector([cartStatsResponseSelector], cartStatsResponse => {
    return cartStatsResponse?.data ?? {};
});
