import React, { useCallback, useState } from 'react';
import Button from '../../../form-elements/Button';
import { Offcanvas } from '../../../Offcanvas/Offcanvas';
import { CategoryLink, ExternalLink } from '../../../form-elements/Link';

function MobileNavigation({ categories, setSearchbarVisible }) {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const setSidebarVisibleWithSideEffect = useCallback(
        visible => {
            if (visible) {
                // Hide searchbar dropdown if the sidebar gets shown.
                setSearchbarVisible(false);
                setSidebarVisible(true);
            } else {
                setSidebarVisible(false);
            }
        },
        [setSearchbarVisible]
    );

    const toggleSidebar = visible => setSidebarVisibleWithSideEffect(visible);
    const closeSidebar = () => setSidebarVisibleWithSideEffect(false);

    return (
        <>
            <div className="sidebar-container uk-width-1-4@m uk-hidden@m">
                <Offcanvas className="sidebar" overlay isVisible={sidebarVisible} onIsVisibleChange={toggleSidebar} flip={true}>
                    <div className="uk-offcanvas-bar uk-padding-remove">
                        <header className="uk-card-header uk-flex uk-flex-middle aps-border-bottom uk-light">
                            <div className="uk-grid-collapse uk-flex-1 uk-grid" data-uk-grid="">
                                <div className="uk-width-expand">
                                    <h3>Kategorien</h3>
                                </div>
                                <button
                                    className="uk-offcanvas-close uk-close uk-icon"
                                    type="button"
                                    data-uk-close=""
                                    onClick={closeSidebar}
                                />
                            </div>
                        </header>
                        <div className="uk-card uk-card-default uk-card-small uk-flex uk-flex-column">
                            <ul className="uk-margin-remove uk-flex-1 uk-accordion" data-uk-accordion="targets: .uk-card">
                                {categories
                                    .filter(category => category.url !== 'elektromobilitaet')
                                    .map(category => (
                                        <li key={category.title}>
                                            <div className="uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-column">
                                                <ExternalLink to="#" className="uk-h5 uk-margin-remove uk-accordion-title">
                                                    {category.title}
                                                </ExternalLink>
                                                <div className="uk-accordion-content">
                                                    <ul className="uk-nav">
                                                        <li onClick={closeSidebar}>
                                                            <CategoryLink category={category}>Zur Kategorie</CategoryLink>
                                                        </li>
                                                        {category.subCategories.map(subCategory => (
                                                            <li key={subCategory.title} onClick={closeSidebar}>
                                                                <CategoryLink category={subCategory}>{subCategory.title}</CategoryLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </Offcanvas>
                <Button
                    onClick={() => setSidebarVisible(visible => !visible)}
                    modifier="link"
                    className="uk-padding-remove uk-text-primary uk-hidden@m uk-flex"
                >
                    <i data-uk-navbar-toggle-icon="" />
                </Button>
            </div>
        </>
    );
}

export default MobileNavigation;
