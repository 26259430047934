import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUIkitReadySelector } from '../../../modules/selectors/UIkit';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRefCallback } from '../../../helpers/refHelper';

export function Dropdown({
    isVisible,
    onIsVisibleChange,
    pos,
    mode,
    delayShow,
    delayHide,
    boundary,
    boundaryAlign,
    flip,
    offset,
    animation,
    duration,
    className: additionalClassName,
    style,
    toggleElement,
    children,
    placeholderChildren,
}) {
    const { refCallback, element: rootElement } = useRefCallback();
    const isUIkitReady = useSelector(isUIkitReadySelector);
    const [dropdownComponent, setDropdownComponent] = useState(null);

    useEffect(() => {
        // Control UIkit dropdown via isVisible
        if (dropdownComponent) {
            if (isVisible) {
                dropdownComponent.show();
            } else {
                dropdownComponent.hide();
            }
        }
    }, [isVisible, dropdownComponent]);

    useEffect(() => {
        // Attach event listeners for UIkit show/hide events
        const createListener = newIsVisible => evt => {
            if (
                // Ignore bubbled events
                evt.target === rootElement &&
                // Ignore events caused by this component.
                isVisible !== newIsVisible
            ) {
                onIsVisibleChange(newIsVisible);
            }
        };

        const showListener = createListener(true);
        const hideListener = createListener(false);

        if (rootElement) {
            rootElement.addEventListener('show', showListener);
            rootElement.addEventListener('hide', hideListener);
        }

        return () => {
            if (rootElement) {
                rootElement.removeEventListener('show', showListener);
                rootElement.removeEventListener('hide', hideListener);
            }
        };
    }, [rootElement, isVisible, onIsVisibleChange]);

    useEffect(() => {
        // Initialize/destroy UIkit dropdown component

        let newDropdownComponent;

        if (isUIkitReady && rootElement) {
            const params = _.omitBy(
                {
                    // Disallow external toggle usage, since this is controlled wrapper around uk-dropdown
                    // toggle: true,
                    pos,
                    mode,
                    'delay-show': delayShow,
                    'delay-hide': delayHide,
                    boundary,
                    boundaryAlign: boundaryAlign,
                    flip,
                    offset,
                    animation,
                    duration,
                },
                _.isNil
            );

            newDropdownComponent = window.UIkit.dropdown(rootElement, params);
            setDropdownComponent(newDropdownComponent);
        }

        return () => {
            if (isUIkitReady && rootElement) {
                if (newDropdownComponent) {
                    newDropdownComponent.$destroy();
                    setDropdownComponent(null);
                } else {
                    console.error('Expected newDropdownComponent, instead got', newDropdownComponent);
                }
            }
        };
    }, [animation, boundary, boundaryAlign, delayHide, delayShow, duration, flip, isUIkitReady, mode, offset, pos, rootElement]);

    return (
        <>
            {toggleElement}
            <div ref={refCallback} className={classnames('uk-dropdown', additionalClassName)} style={style}>
                {isVisible ? children : placeholderChildren}
            </div>
        </>
    );
}

Dropdown.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onIsVisibleChange: PropTypes.func.isRequired,
    // Comma separated list of dropdown trigger behaviour modes: hover, click
    mode: PropTypes.oneOf(['click', 'hover', 'click, hover']),
    // The position of the dropdown.
    pos: PropTypes.oneOf([
        'bottom-left',
        'bottom-center',
        'bottom-right',
        'bottom-justify',
        'top-left',
        'top-center',
        'top-right',
        'top-justify',
        'left-top',
        'left-center',
        'left-bottom',
        'right-top',
        'right-center',
        'right-bottom',
    ]),
    // Delay time in milliseconds before a dropdown is displayed in hover mode.
    delayShow: PropTypes.number,
    // Delay time in milliseconds before a dropdown is hidden in hover mode.
    delayHide: PropTypes.number,
    boundary: PropTypes.string,
    // Align the dropdown to its boundary.
    boundaryAlign: PropTypes.bool,
    // Automatically flip the drop.
    flip: PropTypes.oneOf([false, true, 'x', 'y']),
    // The offset of the dropdown's container.
    offset: PropTypes.number,
    // Space separated names of animations to apply.
    animation: PropTypes.string,
    // Animation duration in milliseconds.
    duration: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    toggleElement: PropTypes.element.isRequired,
    children: PropTypes.node.isRequired,
    placeholderChildren: PropTypes.node.isRequired,
};
