import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import ReduxSuspense from '../../../ReduxSuspense/ReduxSuspense';
import { markenStandortResponseSelector } from '../../../../../modules/selectors/standort/markenStandort';
import { categoriesSelector } from '../../../../../modules/selectors/standort/categories';

import Topbar from './Topbar';
import Mainbar from './Mainbar';
import Navbar from './Navbar';
import { selectedSublocationSelector } from '../../../../../modules/selectors/standort/subLocation';
import classnames from 'classnames';
import LegacySearch from '../../../Search/LegacySearch';
import HolidayBanner from '../../../Header/HolidayBanner';
import loadable from '@loadable/component';

const CouponBanner = loadable(() => import('../../../CouponBanner/Couponbanner'));

function Header({ isCheckout }) {
    const location = useLocation();
    const markenStandortResponse = useSelector(markenStandortResponseSelector);
    const categories = useSelector(categoriesSelector);
    const selectedSublocation = useSelector(selectedSublocationSelector);

    const showNavbar = !isCheckout || ['dankeFuerReservierung', 'dankeUndKaufen', 'danke'].find(v => location.pathname.includes(v));

    const [searchbarVisible, setSearchbarVisible] = useState(false);

    const categoriesWithoutElektroMobilitaet = useMemo(() => {
        if (_.isArray(categories)) return categories.filter(category => category.url !== 'elektromobilitaet');
        return [];
    }, [categories]);

    return (
        <ReduxSuspense response={markenStandortResponse} fallback={null} error={null}>
            {markenStandort => (
                <>
                    <div id="header" className="boundary-align uk-box-shadow-medium" style={{ position: 'sticky', top: 0, zIndex: 980, backgroundColor: '#fff' }}>
                        <Topbar markenStandort={markenStandort} showNavbar={showNavbar} />
                        <Mainbar
                            markenStandort={markenStandort}
                            categories={categories}
                            selectedSublocation={selectedSublocation}
                            showNavbar={showNavbar}
                            setSearchbarVisible={setSearchbarVisible}
                        />
                        <HolidayBanner />
                        <CouponBanner />
                        {showNavbar && <Navbar markenStandort={markenStandort} categories={categoriesWithoutElektroMobilitaet} />}
                        <div
                            className={classnames('uk-width-1-1 uk-margin-remove uk-drop uk-drop-boundary uk-drop-bottom-center', {
                                'uk-open': searchbarVisible,
                            })}
                        >
                            <div className="uk-card uk-card-body uk-card-default">
                                <LegacySearch setSearchbarVisible={setSearchbarVisible} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </ReduxSuspense>
    );
}

export default Header;
