import React, { useMemo } from 'react';
import Image from '../Image/Image';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

function LogoSlider() {
    const markenStandort = useSelector(markenStandortSelector);

    const logos = useMemo(() => {
        return markenStandort.headerCarMakes ?? [];
    }, [markenStandort.headerCarMakes]);

    return (
        <div className="uk-flex uk-flex-middle uk-padding-small uk-padding-remove-vertical">
            <div className="uk-slider uk-slider-container" tabIndex="-1" data-uk-slider="autoplay: true; autoplay-interval: 2000">
                <ul className="uk-slider-items">
                    {logos.map(logo => (
                        <li
                            key={logo}
                            className="uk-padding-small uk-padding-remove-vertical uk-flex uk-flex-center"
                            tabIndex="-1"
                            style={{ order: 1 }}
                        >
                            <Image
                                key={logo}
                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/images/brand-icons/${logo}.jpeg`}
                                alt={logo}
                                style={{ maxHeight: 40 }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default LogoSlider;
