import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import tiresRedirect from './tiresRedirect';
import { useStandortHistory } from '../VHost/StandortHistory';
import { trackEvent } from '../Tracking/Matomo';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { apiClient } from '../../../helpers/apiHelperV2';
import { toast } from '../../../helpers/toastHelper';
import { defaultSeason } from '../../../helpers/tiresHelper';
import { serializeSearchParams } from '../../../helpers/UrlHelper';

export function useSearch() {
    const [searchValue, setSearchValue] = useState('');
    const [requestedSuggestionsValue, setRequestedSuggestionsValue] = useState('');
    const history = useStandortHistory();
    const markenStandort = useSelector(markenStandortSelector);
    const standortUrl = useSelector(standortUrlSelector);

    const { data: suggestions = [] } = useSWR(
        requestedSuggestionsValue,
        async requestedSuggestionsValue => {
            if (!requestedSuggestionsValue) return [];
            const { data } = await apiClient.get('/api/v1/Search/Suggestions', { params: { standortUrl, val: searchValue } });
            return data?.data;
        },
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            onError: err => toast({ err }),
        }
    );

    const handleSuggestionsFetchRequested = ({ value }) => setRequestedSuggestionsValue(value);
    const handleSuggestionsClearRequested = () => setRequestedSuggestionsValue('');
    const handleSearchValueChange = (event, { newValue }) => setSearchValue(newValue);
    const handleSuggestionSelected = (event, props) => executeSearch(props.suggestionValue);

    const executeSearch = searchTerm => {
        if (!searchTerm) return;
        trackEvent({ category: 'Suche', action: 'Submit-Suchanfrage', label: searchTerm, standort: markenStandort });
        const redirect = tiresRedirect({ searchTerm, markenStandort, standortUrl, defaultSeason });
        if (redirect.path !== '') {
            trackEvent({ category: 'Suche', action: 'Weiterleitung-Sonderfall', label: redirect.path, standort: markenStandort });
            history.push({ pathname: redirect.path, state: { search: true } });
        } else {
            history.push({ pathname: '/search', search: serializeSearchParams({ q: searchTerm }), state: { search: true } });
        }
    };

    return {
        suggestions,
        searchValue,
        executeSearch,
        handleSuggestionsFetchRequested,
        handleSuggestionsClearRequested,
        handleSearchValueChange,
        handleSuggestionSelected,
    };
}
