import { HIDE_OPTIONS_SIDEBAR, SET_IS_OPTIONS_SIDEBAR_VISIBLE, SHOW_OPTIONS_SIDEBAR, TOGGLE_OPTIONS_SIDEBAR } from '../actionTypes';

export const showOptionsSidebar = () => ({
    type: SHOW_OPTIONS_SIDEBAR,
    payload: {},
});

export const hideOptionsSidebar = () => ({
    type: HIDE_OPTIONS_SIDEBAR,
    payload: {},
});

export const toggleOptionsSidebar = () => ({
    type: TOGGLE_OPTIONS_SIDEBAR,
    payload: {},
});

export const setIsOptionsSidebarVisible = ({ isVisible }) => ({
    type: SET_IS_OPTIONS_SIDEBAR_VISIBLE,
    payload: { isVisible },
});
