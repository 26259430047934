import { findSeasonOption } from '../../../helpers/tiresHelper';

const createTermMatcher = term => {
    // Only match complete words.
    // FIXME:
    //  This is *not* unicode-aware, only basic ASCII is supported.
    //  For example, "ä|ö|ü" result in a word boundary.
    //  As a result, words with "special" characters will not be correctly matched.
    const regex = new RegExp(`\\b${term}\\b`, 'i');
    return haystack => regex.test(haystack);
};

const matchReifen = createTermMatcher('reifen');
const matchFelge = createTermMatcher('felgen?');
const matchWinter = createTermMatcher('winter');
const matchSommer = createTermMatcher('sommer');
const matchJahr = createTermMatcher('jahr');
const matchKomplett = createTermMatcher('komplett');
const matchStahl = createTermMatcher('stahl');
const matchAlu = createTermMatcher('alu');
const matchLeichtmetall = createTermMatcher('leichtmetall');
const matchSchloss = createTermMatcher('(schloss|schloesser|schlösser)');
const matchSuv = createTermMatcher('suv');
const matchOffroad = createTermMatcher('offroad');
const matchLkw = createTermMatcher('lkw');
const matchPkw = createTermMatcher('pkw');
const matchTasche = createTermMatcher('taschen?');

// Hersteller
const matchBarum = createTermMatcher('barum');
const matchBridgestone = createTermMatcher('bridgestone');
const matchConti = createTermMatcher('conti');
const matchDebica = createTermMatcher('debica');
const matchDunlop = createTermMatcher('dunlop');
const matchFirestone = createTermMatcher('firestone');
const matchFulda = createTermMatcher('fulda');
const matchGoodyear = createTermMatcher('goodyear');
const matchHankook = createTermMatcher('hankook');
const matchKleber = createTermMatcher('kleber');
const matchMaxxis = createTermMatcher('maxxis');
const matchMichelin = createTermMatcher('michelin');
const matchNankang = createTermMatcher('nankang');
const matchNokian = createTermMatcher('nokian');
const matchPirelli = createTermMatcher('pirelli');
const matchSava = createTermMatcher('sava');
const matchSemperit = createTermMatcher('semperit');
const matchTecar = createTermMatcher('tecar');
const matchUniroyal = createTermMatcher('uniroyal');
const matchVredestein = createTermMatcher('vredestein');

/**
 * @param searchTerm
 * @param markenStandort
 * @param standortUrl
 * @param defaultSeason {'winterreifen'|'sommerreifen'}
 */
const tiresRedirect = ({ searchTerm, markenStandort, standortUrl, defaultSeason }) => {
    let clearObj = {
        path: '',
        carType: '',
        saison: '',
        breite: '',
        querschnitt: '',
        durchmesser: '',
        hersteller: '',
        speedindex: '',
        loadindex: '',
        checkApi: false,
        blacklist: {
            winterreifen: false,
            sommerreifen: false,
            ganzjahresreifen: false,
            alufelgen: false,
            stahlfelgen: false,
        },
    };

    let retObj = { ...clearObj };
    let newPath = '';
    let pathSelected = false;
    let checkApi = false;
    let blacklist = {
        winterreifen: false,
        sommerreifen: false,
        ganzjahresreifen: false,
        alufelgen: false,
        stahlfelgen: false,
    };

    //if (searchValue.match(/reifen/gi) || searchValue.match(/felge/gi) || searchValue.match(/rad/gi)) {
    if (matchReifen(searchTerm) || matchFelge(searchTerm)) {
        // Logik: Jahreszeit direkt => setzen, wenn im shop nicht ausgeschlossen
        // ansonsten: Oktober bis März: Winterreifen / Rest Sommerreifen

        // Füllen der Variablen aufgrund Blacklist für Standort, Category
        let winterAllowed = true;
        let summerAllowed = true;
        let alufelgenAllowed = true;
        let stahlfelgenAllowed = true;
        let allSeasonAllowed = true;
        let komplettradAllowed = true;
        if (markenStandort.categoryBlacklist) {
            markenStandort.categoryBlacklist.forEach(function (listItem) {
                switch (listItem.url) {
                    // Reifen
                    case 'winterreifen':
                        winterAllowed = false;
                        blacklist.winterreifen = true;
                        break;
                    case 'sommerreifen':
                        summerAllowed = false;
                        blacklist.sommerreifen = true;
                        break;
                    case 'ganzjahresreifen':
                        allSeasonAllowed = false;
                        blacklist.ganzjahresreifen = true;
                        break;
                    // nicht mehr existent ?
                    case 'komplettrad':
                        komplettradAllowed = false;
                        break;
                    // Felgen
                    case 'alufelgen':
                        alufelgenAllowed = false;
                        blacklist.alufelgen = true;
                        break;
                    case 'stahlfelgen':
                        stahlfelgenAllowed = false;
                        blacklist.stahlfelgen = true;
                        break;
                    default:
                        break;
                }
            });
        }
        let saison = '';
        if (!pathSelected && matchWinter(searchTerm) && winterAllowed) {
            newPath = '/winterreifen';
            saison = 'Winter';
            pathSelected = true;
        }
        if (!pathSelected && matchSommer(searchTerm) && summerAllowed) {
            newPath = '/sommerreifen';
            saison = 'Sommer';
            pathSelected = true;
        }
        if (!pathSelected && matchJahr(searchTerm) && allSeasonAllowed) {
            newPath = '/ganzjahresreifen';
            saison = 'Ganzjahr';
            pathSelected = true;
        }
        // Komplettrad wird als eigener Artikel geführt; deswegen hier keine
        // Umleitung, sondern finden über normale Suche (siehe unten)
        if (!pathSelected && matchKomplett(searchTerm) && komplettradAllowed) {
            newPath = '/komplettrad';
            saison = '';
            pathSelected = true;
        }
        if (!pathSelected && matchFelge(searchTerm)) {
            // Felgenschlösser ausschließen (eigene Kategorie)
            if (!matchSchloss(searchTerm)) {
                if (!pathSelected && matchStahl(searchTerm) && stahlfelgenAllowed) {
                    newPath = '/stahlfelgen';
                    pathSelected = true;
                }
                if (!pathSelected && (matchAlu(searchTerm) || matchLeichtmetall(searchTerm)) && alufelgenAllowed) {
                    newPath = '/alufelgen';
                    pathSelected = true;
                }
                if (!pathSelected && alufelgenAllowed) {
                    newPath = '/alufelgen';
                    pathSelected = true;
                }
            }
        }
        if (!pathSelected && !matchFelge(searchTerm)) {
            newPath = `/${defaultSeason}`;
            saison = findSeasonOption({ season: defaultSeason }).type;
        }
        // Start Reifen
        if (!matchFelge(searchTerm)) {
            // weitere Aufgliederung für Reifen
            let carType = '';
            if (matchSuv(searchTerm) || matchOffroad(searchTerm)) {
                carType = 'SUV';
            }
            if (matchLkw(searchTerm)) {
                carType = 'LLKW';
            }
            if (matchPkw(searchTerm)) {
                carType = 'PKW';
            }
            let newSearchValue = searchTerm;
            let breite = '';
            // 125 ... 355
            if (newSearchValue.match(/[/ ][1-3][0-9]5/gi)) {
                breite = newSearchValue.match(/[1-9][0-9]5/gi)[0];
                const regex = new RegExp(breite, 'gi');
                newSearchValue = newSearchValue.replace(regex, '');
            }
            let querschnitt = '';
            // 25 ... 90
            if (newSearchValue.match(/[/ ][2-9][05]/gi)) {
                querschnitt = newSearchValue.match(/[2-9][05]/gi)[0];
                const regex = new RegExp(querschnitt, 'gi');
                newSearchValue = newSearchValue.replace(regex, '');
            }
            let durchmesser = '';
            if (newSearchValue.match(/[/ ]22.5/gi)) {
                durchmesser = '22.5';
            } else {
                if (newSearchValue.match(/[/ r][12][0-9]/gi)) {
                    durchmesser = newSearchValue.match(/[12][0-9]/gi)[0];
                    let intDurchmesser = parseInt(durchmesser);
                    if (isNaN(intDurchmesser) || intDurchmesser < 13 || intDurchmesser > 24) {
                        durchmesser = '';
                    }
                }
            }

            // Suchen nach Hersteller
            let hersteller = '';
            if (matchBarum(newSearchValue)) hersteller = 'Barum';
            if (matchBridgestone(newSearchValue)) hersteller = 'Bridgestone';
            if (matchConti(newSearchValue)) hersteller = 'Continental';
            if (matchDebica(newSearchValue)) hersteller = 'Debica';
            if (matchDunlop(newSearchValue)) hersteller = 'Dunlop';
            if (matchFirestone(newSearchValue)) hersteller = 'Firestone';
            if (matchFulda(newSearchValue)) hersteller = 'Fulda';
            if (matchGoodyear(newSearchValue)) hersteller = 'Goodyear';
            if (matchHankook(newSearchValue)) hersteller = 'Hankook';
            if (matchKleber(newSearchValue)) hersteller = 'Kleber';
            if (matchMaxxis(newSearchValue)) hersteller = 'Maxxis';
            if (matchMichelin(newSearchValue)) hersteller = 'Michelin';
            if (matchNankang(newSearchValue)) hersteller = 'Nankang';
            if (matchNokian(newSearchValue)) hersteller = 'Nokian';
            if (matchPirelli(newSearchValue)) hersteller = 'Pirelli';
            if (matchSava(newSearchValue)) hersteller = 'Sava';
            if (matchSemperit(newSearchValue)) hersteller = 'Semperit';
            if (matchTecar(newSearchValue)) hersteller = 'TECAR';
            if (matchUniroyal(newSearchValue)) hersteller = 'Uniroyal';
            if (matchVredestein(newSearchValue)) hersteller = 'Vredestein';

            // direkter Aufruf Reifenliste? wird in Search geprüft und hier vorbereitet
            //            if (carType !== "" && saison !== "" && breite !== "" && querschnitt !== "" && durchmesser !== "") {
            if (saison !== '' && breite !== '' && querschnitt !== '' && durchmesser !== '') {
                // setzen checkApi führt zur Vorabsuche Reifen  im Search und zum Weiterleiten
                // der Suchergebnisse zur Reifenliste, wenn Reifen gefunden werden
                checkApi = false;
            }

            // Standardvorbesetzung PKW, wenn nicht festgelegt Vorgabe TEchno, Ticket 55081289
            if (carType === '') {
                carType = 'PKW';
            }
            // setzen Standardwert für PKW, falls keine Breite, Querschnitt, Durchmesser
            // aber trotzdem nicht direkt -> Reifendetails, sondern -> Reifenkonfigurator
            if (carType === 'PKW' && breite === '' && querschnitt === '' && durchmesser === '') {
                // Standardvorgabe Techno je Saison Ticket 55081289
                if (saison === 'Sommer') {
                    breite = '205';
                    querschnitt = '55';
                    durchmesser = '16';
                } else {
                    if (saison === 'Winter') {
                        breite = '205';
                        querschnitt = '55';
                        durchmesser = '16';
                    } else {
                        //Ganzjahr
                        breite = '205';
                        querschnitt = '55';
                        durchmesser = '16';
                    }
                }
            }
            retObj = {
                path: newPath,
                carType: carType,
                saison: saison,
                breite: breite,
                querschnitt: querschnitt,
                durchmesser: durchmesser,
                hersteller: hersteller,
                checkApi: checkApi,
                blacklist: blacklist,
            };
            // Komplettrad: nur Pfad
            if (newPath === '/komplettrad') {
                retObj = {
                    // FIXME: why empty path?
                    path: '',
                    carType: '',
                    saison: '',
                    breite: '',
                    querschnitt: '',
                    durchmesser: '',
                    checkApi: false,
                    hersteller: '',
                    blacklist: blacklist,
                };
            }
            // TECAR in suche - Händler prüfen und auf TECAR-Landingpage umleiten,
            // wenn nicht Reifen direkt angegeben (dann anzeigen mit Filter TECAR)
            if (hersteller === 'TECAR' && retObj.checkApi === false && markenStandort.tecarHaendler === true) {
                retObj.path = '/home/tecarLandingpage/reifen-finden';
            }
            // Spezielle Artikel ausschließen
            if (
                newPath === '/winterreifen' ||
                newPath === '/sommerreifen' ||
                newPath === '/ganzjahresreifen' ||
                newPath === '/home/tecarLandingpage/reifen-finden'
            ) {
                // Für Reifentaschenset
                if (matchTasche(newSearchValue)) {
                    retObj = { ...clearObj };
                }
            }
            // ohne Autotyp wird aufgrund rad z-B. auch fahrrad auf reifen umgeleitet
            //if (carType === "") {
            //    retObj = { ...clearObj };
            //}
            // Ende Reifen
        } else {
            // Felgen: nur Pfad
            retObj = {
                path: newPath,
                checkApi: checkApi,
                blacklist: blacklist,
            };
        }
    }

    // Spezielle Bedingungen im nationalen Shop
    if (standortUrl === 'de') {
        //vorerst keine Felgen
        if (matchFelge(searchTerm)) {
            retObj = { ...clearObj };
        }

        // kein Komplettrad! eigener Artikel, nicht national!
        if (matchKomplett(searchTerm)) {
            retObj = { ...clearObj };
        }
    }

    return retObj;
};

export default tiresRedirect;
