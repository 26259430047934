import React from 'react';
import { connect } from 'react-redux';
import { cartStatsSelector } from '../../../modules/selectors/standort/session/cartStats';
import { fetchCartStatsIfNeeded } from '../../../modules/actions/standort/session/cartStats';
import { frontloadConnect } from 'react-frontload';
import StandortLink from '../VHost/StandortLink';

const CartLink = ({ cartStats }) => {
    return (
        <StandortLink to="/warenkorb">
            <div className="uk-position-relative">
                <span className="uk-margin-small-right" data-uk-icon="icon: cart; ratio: 1.5" />
                <div className="uk-badge uk-position-absolute" style={{ height: 20, minWidth: 20, top: -8, right: 0 }}>
                    {cartStats.numOfPos || 0}
                </div>
            </div>
        </StandortLink>
    );
};

const frontload = async props => {
    await Promise.all([props.fetchCartStatsIfNeeded()]);
};

const mapStateToProps = state => {
    return {
        cartStats: cartStatsSelector(state),
    };
};

const mapDispatchToProps = { fetchCartStatsIfNeeded };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(frontloadConnect(frontload, { onUpdate: true, noServerRender: true })(CartLink));
