import { useCallback, useState } from 'react';

// Reference: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export function useRefCallback() {
    const [element, setElement] = useState(null);

    const refCallback = useCallback(newElement => {
        if (newElement !== null) {
            setElement(newElement);
        }
    }, []);

    return { refCallback, element };
}
