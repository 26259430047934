import React from 'react';
import Crumb from './Crumb';

function BreadCrumbList({ crumbs }) {
    return (
        <div className="uk-breadcrumb uk-text-capitalize uk-text-truncate">
            {crumbs.map((crumb, index) => (
                <Crumb key={index} crumb={crumb} isActive={index + 1 === crumbs.length} />
            ))}
        </div>
    );
}

export default BreadCrumbList;
