import React from 'react';
import { connect, useSelector } from 'react-redux';
import Image from '../../../Image/Image';
import ContactModal from '../../../Anfrage/ContactModal';
import { userResponseSelector } from '../../../../../modules/selectors/auth';
import { signout } from '../../../../../modules/actions/user';
import ReduxSuspense from '../../../ReduxSuspense/ReduxSuspense';
import StandortLink from '../../../VHost/StandortLink';
import { cartStatsSelector } from '../../../../../modules/selectors/standort/session/cartStats';
import { fetchCartStatsIfNeeded } from '../../../../../modules/actions/standort/session/cartStats';
import { frontloadConnect } from 'react-frontload';

function Topbar({ markenStandort, showNavbar, signout, cartStats }) {
    const userResponse = useSelector(userResponseSelector);

    const handleSignoutSubmit = async () => await signout();

    return (
        <section className="uk-section uk-section-secondary uk-padding-small uk-padding-remove-horizontal aps-border-bottom">
            <div className="uk-container boundary-align-embed">
                <div className="uk-grid uk-flex-middle" data-uk-grid="">
                    <div className="uk-width-expand">
                        {!markenStandort.isTechno && (
                            <Image
                                skipResize
                                src="https://techno-images.s3.eu-central-1.amazonaws.com/icons/aps-small.svg"
                                alt="POWERED BY AUTOTEILE PLUS SERVICE"
                                width={300}
                            />
                        )}
                    </div>
                    <div className="uk-width-expand">
                        <ul className="boundary uk-subnav aps-subnav uk-flex uk-flex-right uk-margin-remove" data-uk-margin="">
                            <ReduxSuspense response={userResponse} fallback={null} error={null}>
                                {user => (
                                    <li>
                                        {user && showNavbar && (
                                            <button
                                                className="uk-button uk-button-link"
                                                style={{ textDecoration: 'unset' }}
                                                onClick={handleSignoutSubmit}
                                            >
                                                <i className="fa fa-sign-out fa-14x uk-margin-xsmall-right" />
                                                <span className="uk-visible@m">Logout</span>
                                            </button>
                                        )}
                                    </li>
                                )}
                            </ReduxSuspense>
                            <li>
                                <ContactModal>
                                    {({ openModal }) => (
                                        <button
                                            className="uk-button uk-button-link"
                                            style={{ textDecoration: 'unset' }}
                                            onClick={openModal}
                                        >
                                            <i className="fa fa-envelope fa-14x uk-margin-xsmall-right" />
                                            <span className="uk-visible@m">Kontakt</span>
                                        </button>
                                    )}
                                </ContactModal>
                            </li>
                            <li>
                                <StandortLink to="/account">
                                    <i className="fa fa-user fa-14x uk-margin-xsmall-right" />
                                    <span className="uk-visible@m">Kundenkonto</span>
                                </StandortLink>
                            </li>
                            <li>
                                <StandortLink to="/warenkorb" className="uk-inline">
                                    <span className="uk-position-relative">
                                        <span className="uk-badge aps-badge uk-position-absolute" style={{ top: -7, right: 0 }}>
                                            {cartStats.numOfPos || 0}
                                        </span>
                                        <i className="fa fa-shopping-cart fa-14x uk-margin-xsmall-right" />
                                    </span>
                                    <span className="uk-visible@m">Warenkorb</span>
                                </StandortLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

const frontload = async props => {
    await Promise.all([props.fetchCartStatsIfNeeded()]);
};

const mapStateToProps = state => {
    return {
        cartStats: cartStatsSelector(state),
    };
};

const mapDispatchToProps = { fetchCartStatsIfNeeded, signout };

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true, noServerRender: true })(Topbar));
