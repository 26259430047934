import React from 'react';
import Autosuggest from 'react-autosuggest';
import { useSearch } from './useSearch';

function Search({ setSearchbarVisible }) {
    const {
        suggestions,
        searchValue,
        executeSearch,
        handleSuggestionsFetchRequested,
        handleSuggestionsClearRequested,
        handleSearchValueChange,
    } = useSearch();

    return (
        <div className="uk-margin">
            <div className="uk-inline aps-form-width-medium">
                <button
                    className="uk-form-icon uk-form-icon-flip aps-form-icon"
                    data-uk-icon="icon: search"
                    onClick={() => {
                        executeSearch(searchValue);
                        setSearchbarVisible(false);
                    }}
                    onKeyDown={evt => {
                        if (evt && evt.key === 'Enter') {
                            executeSearch(searchValue);
                            setSearchbarVisible(false);
                        }
                    }}
                />
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={handleSuggestionsClearRequested}
                    onSuggestionSelected={(event, props) => {
                        executeSearch(props.suggestionValue);
                        setSearchbarVisible(false);
                    }}
                    getSuggestionValue={suggestion => suggestion.title}
                    renderSuggestion={suggestion => suggestion.title}
                    inputProps={{
                        placeholder: 'Suche ...',
                        value: searchValue,
                        onChange: handleSearchValueChange,
                    }}
                />
            </div>
        </div>
    );
}

export default Search;
