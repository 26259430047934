import React, { useEffect, useState } from 'react';
import Button from '../form-elements/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const VehicleSelectionPanel = loadable(() => import('./VehicleSelectionPanel'));

function VehicleSelectionToggle() {
    const [isVisible, setIsVisible] = useState(false);
    const [isSelected, setIsSelected] = useState(false)
    const vehicle = useSelector(state => state.vehicle)
    
    const checkIfSelected = (vehicle) => {
        return !_.isEmpty(vehicle.label) && !_.isEmpty(vehicle.datECodePrefix)
    }

    useEffect(() => {
        setIsSelected(checkIfSelected(vehicle))
    },[vehicle])
    return (
        <Dropdown
            isVisible={isVisible}
            onIsVisibleChange={isVisible => setIsVisible(isVisible)}
            className="uk-card uk-card-default uk-card-small"
            boundary=".vehicle-selection-dropdown-boundary"
            pos="bottom-right"
            boundaryAlign
            mode="hover"
            toggleElement={
                // Empty onClick, since hover can also trigger the dropdown
                isVisible ? 
                <Button modifier="link" onClick={() => {}}>
                    <i className="fa fa-car fa-2x" aria-hidden="true" />
                </Button>
                :
                isSelected ?
                <Button modifier="link" onClick={() => {}}>
                    <i className="uk-position-absolute fa fa-check fa-2x" style={{ top: -12, right: -12, color: "var(--shop-main)" }}></i>
                    <i className="fa fa-car fa-2x" aria-hidden="true" />
                </Button>
                :
                <Button modifier="link" onClick={() => {}}>
                    <div className="uk-badge uk-position-absolute" style={{ height: 20, minWidth: 20, top: -8, right: -8 }}></div>
                    <i className="fa fa-car fa-2x" aria-hidden="true" />
                </Button>
            }
            placeholderChildren={<div style={{ minWidth: 240, height: 10 }} />}
        >
            <VehicleSelectionPanel />
        </Dropdown>
    );
}

export default VehicleSelectionToggle;
