import { useEffect, useState } from 'react';

/**
 * @returns {{width: number|undefined, height: number|undefined}}
 */
export default function useWindowSize() {
    const [size, setSize] = useState({ width: undefined, height: undefined });

    useEffect(() => {
        function updateSize() {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}
