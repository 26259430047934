import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUIkitReadySelector } from '../../../modules/selectors/UIkit';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRefCallback } from '../../../helpers/refHelper';

export function Offcanvas({
    isVisible,
    onIsVisibleChange,
    mode,
    flip,
    overlay,
    escClose,
    bgClose,
    container,
    children,
    className: additionalClassName,
}) {
    const { refCallback, element: asideElement } = useRefCallback();
    const isUIkitReady = useSelector(isUIkitReadySelector);
    const [offcanvasComponent, setOffcanvasComponent] = useState(null);

    useEffect(() => {
        // Control UIkit offcanvas via isVisible
        if (offcanvasComponent) {
            if (isVisible) {
                offcanvasComponent.show();
            } else {
                offcanvasComponent.hide();
            }
        }
    }, [isVisible, offcanvasComponent]);

    useEffect(() => {
        // Attach event listeners for UIkit show/hide events
        const createListener = newIsVisible => evt => {
            if (
                // Ignore bubbled events
                evt.target === asideElement &&
                // Ignore events caused by this component.
                isVisible !== newIsVisible
            ) {
                onIsVisibleChange(newIsVisible);
            }
        };

        const showListener = createListener(true);
        const hideListener = createListener(false);

        if (asideElement) {
            asideElement.addEventListener('show', showListener);
            asideElement.addEventListener('hide', hideListener);
        }

        return () => {
            if (asideElement) {
                asideElement.removeEventListener('show', showListener);
                asideElement.removeEventListener('hide', hideListener);
            }
        };
    }, [asideElement, isVisible, onIsVisibleChange]);

    useEffect(() => {
        // Initialize/destroy UIkit offcanvas component

        let newOffcanvasComponent;

        if (isUIkitReady && asideElement) {
            newOffcanvasComponent = window.UIkit.offcanvas(
                asideElement,
                _.omitBy(
                    {
                        mode,
                        flip,
                        overlay,
                        'esc-close': escClose,
                        'bg-close': bgClose,
                        container,
                    },
                    _.isNil
                )
            );
            setOffcanvasComponent(newOffcanvasComponent);
        }

        return () => {
            if (isUIkitReady && asideElement) {
                if (newOffcanvasComponent) {
                    newOffcanvasComponent.$destroy();
                    setOffcanvasComponent(null);
                } else {
                    console.error('Expected newOffcanvasComponent, instead got', newOffcanvasComponent);
                }
            }
        };
    }, [asideElement, bgClose, container, escClose, flip, isUIkitReady, mode, overlay]);

    return (
        <aside ref={refCallback} className={classnames('uk-offcanvas', additionalClassName)}>
            {children}
        </aside>
    );
}

Offcanvas.propTypes = {
    //Off-canvas animation mode
    mode: PropTypes.oneOf(['slide', 'reveal', 'push', 'none']),
    //Flip off-canvas to the right side.,
    flip: PropTypes.bool,
    //Display the off-canvas together with an overlay.
    overlay: PropTypes.bool,
    //Close the off-canvas when the Esc key is pressed.
    escClose: PropTypes.bool,
    //Close the off-canvas when the background is clicked.
    bgClose: PropTypes.bool,
    //Define a target container via a selector to specify where the off-canvas should be appended in the DOM. Setting it to false will prevent this behavior.
    container: PropTypes.string,
    className: PropTypes.string,
    isVisible: PropTypes.bool.isRequired,
    onIsVisibleChange: PropTypes.func.isRequired,
    children: PropTypes.node,
};
