import React from 'react';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import Image from '../../../Image/Image';
import LogoSlider from '../../../Header/LogoSlider';
import { NoSSR } from '../../../../../helpers/SSRHelper';
import MobileNavigation from './MobileNavigation';
import Button from '../../../form-elements/Button';
import { InternalLink } from '../../../form-elements/Link';
import LegacySearch from '../../../Search/LegacySearch';
import { selectedCarBrandSelector } from '../../../../../modules/selectors/standort/subLocation';

function Mainbar({ markenStandort = {}, categories = [], selectedSublocation = {}, showNavbar, setSearchbarVisible }) {
    const selectedCarBrand = useSelector(selectedCarBrandSelector);
    return (
        <div className="uk-tile uk-tile-default uk-padding-small uk-padding-remove-horizontal">
            <div className="uk-container">
                <div className="uk-grid uk-grid-small uk-flex-middle" data-uk-grid="" style={{ height: 60 }}>
                    <div className="uk-width-3-5@m uk-width-expand">
                        <div className="uk-grid uk-grid-small uk-flex-middle" data-uk-grid="">
                            <div className={markenStandort.isTechno ? 'uk-width-1-1 uk-width-2-3@s' : 'uk-width-auto aps-max-width@s'}>
                                <InternalLink to="/">
                                    {markenStandort.isTechno ? (
                                        <Image
                                            src="https://techno-images.s3.eu-central-1.amazonaws.com/icons/aps_logo_national.jpg"
                                            alt={`${markenStandort.title} Logo`}
                                            resizeHeight={60}
                                            style={{ maxHeight: 60 }}
                                        />
                                    ) : (
                                        markenStandort.gesellschafterNr &&
                                        markenStandort.standortNr && (
                                            <Image
                                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Logo.jpg`}
                                                alt={`${markenStandort.title} Logo`}
                                                resizeHeight={60}
                                                style={{ maxHeight: 60 }}
                                            />
                                        )
                                    )}
                                </InternalLink>
                            </div>
                            <div className="uk-width-expand">
                                <NoSSR>
                                    <LogoSlider />
                                </NoSSR>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-2-5@m uk-width-auto uk-flex uk-flex-right uk-margin-auto-left">
                        <div className="uk-grid uk-grid-small uk-flex-middle uk-flex-nowrap" data-uk-grid="">
                            <div className="uk-width-expand uk-flex uk-flex-right uk-visible@m">
                                <HashLink to={`/${markenStandort.url}#map`} className="uk-link-reset" smooth>
                                    <div className="uk-grid uk-grid-small uk-flex-middle" data-uk-grid="">
                                        <div className="uk-width-auto">
                                            {!markenStandort.isTechno && <i className="fa fa-map-marker fa-3x" />}
                                        </div>
                                        <div className="uk-width-expand uk-text-small uk-text-center" style={{ maxWidth: 150 }}>
                                            {selectedSublocation.anschrift && selectedSublocation.anschrift.firma ? (
                                                <div>
                                                    {selectedSublocation.anschrift.strasse}
                                                    <div>{`${selectedSublocation.anschrift.plz} ${selectedSublocation.anschrift.ort}`}</div>
                                                    {selectedCarBrand && <div className="uk-text-bold">{selectedCarBrand}</div>}
                                                </div>
                                            ) : (
                                                markenStandort.isAreaShop &&
                                                markenStandort.headerSlogan &&
                                                !markenStandort.isTechno &&
                                                markenStandort.headerSlogan
                                            )}
                                        </div>
                                    </div>
                                </HashLink>
                            </div>
                            <div className="uk-hidden@m">
                                <Button className="uk-button-small" hideArrow onClick={() => setSearchbarVisible(visible => !visible)}>
                                    <span data-uk-icon="search" />
                                </Button>
                            </div>
                            <div>
                                <MobileNavigation categories={categories} setSearchbarVisible={setSearchbarVisible} />
                            </div>

                            <div className="uk-width-auto uk-visible@m">
                                {showNavbar && <LegacySearch setSearchbarVisible={setSearchbarVisible} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mainbar;
