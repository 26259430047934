import { FETCH_ADD_CRUMBS_SUCCESS } from '../actionTypes';

export const saveAdditionalCrumbsIfNeeded = (additionalCrumbs) => {

    additionalCrumbs = additionalCrumbs.map(item => {
        if (item.key) {
            item.name = item.label;
            item.label = `${item.key}/${item.label}`;
        }
        return { 
            label: item.label, 
            name: item.name
         };
    })

    return async function (dispatch, getState) {
        dispatch({
            type: FETCH_ADD_CRUMBS_SUCCESS,
            payload: { additionalCrumbs },
        });
    }
};
