import React from 'react';
import { useDispatch } from 'react-redux';
import { showOptionsSidebar } from '../../../modules/actions/optionsSidebar';

function SubNavLeft() {
    const dispatch = useDispatch();

    return (
        <ul className="uk-subnav uk-margin-remove-bottom">
            <li>
                <span className="uk-margin-small-right" data-uk-icon="settings" onClick={() => dispatch(showOptionsSidebar())} />
            </li>
        </ul>
    );
}

export default SubNavLeft;
